<template>
	<div class="box">
		<!-- header头部 -->
		<headers></headers>
		<!-- 标题 -->
		<div class="power-top">
			<img src="../../assets/images/del.png" alt="" class="del" @click="back" />
			<div class="power-title">
				<img src="../../assets/images/t-l.png" alt="" />
				<span>{{title}}</span>
				<img src="../../assets/images/t-r.png" alt="" />
			</div>
		</div>
		<div class="power-con">
			<section>
				<div class="power-con-title">
					<div class="power-con-title-l">
						<span>{{title}}</span>
						<img src="../../assets/images/jin.png" alt="" />

					</div>
					<div style="display: flex; align-items: center; margin-left: 50px; margin-bottom: 30px;">
						<el-date-picker @change="daochutime" value-format="yyyy-M-d" start-placeholder="开始日期"
							end-placeholder="结束日期" v-model="time" type="daterange"></el-date-picker>
						<div class="daochu" @click="exports">导出</div>
					</div>
					<!-- <el-date-picker @change="getrq" value-format="yyyy-M-d" placeholder="请选择" v-model="value1" type="date"></el-date-picker> -->
				</div>
				<div class="power-con-list">
					<el-table :data="tableData" height="420"
						:header-cell-style="{ background: '#011633', color: '#D0DEEE' }" :row-style="{ height: '57px' }"
						:cell-style="TableCellStyle" stripe style="width: 100%">
						<el-table-column prop="xuhao" label="序号" width="80"></el-table-column>
						<el-table-column prop="huoming" label="货名" width="120"></el-table-column>
						<el-table-column prop="guige" label="规格"></el-table-column>
						<el-table-column label="出入标识">
							<template v-slot="scope">
								<span v-if="scope.row.type == 1 && type==0">入厂</span>
								<span v-else-if="scope.row.type == 2 && type==0">出厂</span>
								<span v-else-if="scope.row.type == 1 && type==1">入厂</span>
								<span v-else-if="scope.row.type == 2 && type==1">出厂</span>
							</template>
						</el-table-column>
						<el-table-column prop="chehao" label="车号"></el-table-column>
						<template v-if="type==0">
							<el-table-column prop="sh_unit" label="收货单位" width="280"></el-table-column>
						</template>
						<el-table-column prop="maozhong" label="毛重(T)"></el-table-column>
						<el-table-column prop="pizhong" label="皮重(T)"></el-table-column>
						<el-table-column prop="jingzhong" label="地磅净重(T)"></el-table-column>
						<el-table-column prop="gengxinren" label="过磅员"></el-table-column>
						<el-table-column prop="fguobang" label="第一次过磅时间" width="180"></el-table-column>
						<el-table-column prop="sguobang" label="第二次过磅时间" width="180"></el-table-column>
						<el-table-column width="80" label="操作">
							<template v-slot="scope"><el-button class="btn"
									@click="look(scope.row)">查看</el-button></template>
						</el-table-column>
					</el-table>
				</div>
				<div class="block">
					<div class="homepage" @click="shouye">首页</div>
					<el-pagination @current-change="currentChange" :current-page="currentPage" :page-size="pagesize"
						layout=" prev, pager, next, jumper" :total="total"></el-pagination>
					<div class="lastpage" @click="last">末页</div>
				</div>
			</section>
		</div>
		<el-dialog title="" :visible.sync="dialogVisible" width="900px" :before-close="handleClose">
			<div class="sjdetail flex">
				<div><span>车号：</span><span>{{detail.chehao}}</span></div>
				<div><span>第一次过磅时间：</span><span>{{detail.fguobang}}</span></div>
				<div><span>第二次过磅时间：</span><span>{{detail.sguobang}}</span></div>
			</div>
			<div class="tudetail">
				<div v-for="(tu,index) in detail.imgs" :key="index">
					<img :src="tu" alt="">
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="dialogVisible = false">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	import headers from '../../components/headers.vue';
	export default {
		components: {
			headers
		},

		data() {
			return {
				time: '',
				time2: '',
				value1: '',
				page: 0,
				total: 0, //总条目数
				pagesize: 10, //每页显示条目个数
				currentPage: 1, //当前页数
				flag: false,
				tableData: [],
				dialogVisible: false,
				detail: {},
				starttime: "",
				endtime: "",
				totalpage: 0,
				type: 0,
				title: '地磅实时数据'
			};
		},

		mounted() {
			let param = this.$route.query
			this.type = param.type
			if (this.type == 1) {
				this.title = '毛砂实时数据'
			} else {
				this.title = '成品砂实时数据'
			}

			const date = new Date
			const day = date.getDate()
			const month = date.getMonth() + 1
			const year = date.getFullYear()
			this.getdblist(this.currentPage)
		},
		methods: {
			xg() {
				// console.log(11)
			},
			async xgdibang(item, a) {
				// console.log(22)
				let data = {
					d_id: item.id,
					shuizhong: item.shuizhong,
					bdmz: item.bdmz,
					bdjz: item.bdjz
				}
				let that = this
				const res = await this.$postToken("datas/update-dibang", data)
				if (res.data.code == 200) {
					that.getdblist(this.currentPage)
				} else {
					this.$message({
						message: res.data.msg,
						type: 'error'
					});
				}
			},
			look(e) {
				// console.log(e)
				// this.detail.imgs = []
				this.detail = e
				this.dialogVisible = true
			},

			handleClose() {
				this.dialogVisible = false
			},

			getrq(e) {
				this.getdblist(this.currentPage)
			},

			currentChange(e) {
				this.currentPage = e;
				this.getdblist(this.currentPage);
			},

			shouye() {
				this.currentPage = 1;
				this.getdblist(this.currentPage);
			},

			last() {
				this.currentPage = this.totalpage;
				this.getdblist(this.currentPage);
			},

			//设置表格背景颜色
			TableCellStyle(e) {
				if (e.rowIndex % 2 === 0) {
					return 'background-color:#021d3d; border:none; font-weight: normal;';
				} else {
					return 'background-color: #011633;  border:none; font-weight: normal;';
				}
			},

			// 获取地磅数据列表
			async getdblist(page) {
				// console.log(this.time2);
				if (this.type == 0) {
					let res = await this.$postToken('datas/dibang-list', {
						time: this.value1,
						start_time: this.starttime,
						end_time: this.endtime,
						page_index: page,
						page_size: this.pagesize
					});
					if (res.data.code == 200) {
						this.tableData = res.data.result.list;
						this.total = res.data.result.count;
						this.totalpage = res.data.result.page
					}
				} else {
					let res = await this.$postToken('datas/maosha-list', {
						time: this.value1,
						start_time: this.starttime,
						end_time: this.endtime,
						page_index: page,
						page_size: this.pagesize
					});
					if (res.data.code == 200) {
						this.tableData = res.data.result.list;
						this.total = res.data.result.count;
						this.totalpage = res.data.result.page
					}
				}

			},
			// 导出
			daochutime(e) {
				// console.log(e==null)
				if (this.time) {
					this.starttime = this.time[0]
					this.endtime = this.time[1]
				}
				if (e == null) {
					this.starttime = ""
					this.endtime = ""
				}
				this.getdblist(this.currentPage)
			},
			async exports() {
				if (this.starttime == "" || this.endtime == "") {
					this.$message({
						message: '请选择导出时间范围',
						type: 'warning'
					})
				} else {
					if (this.type == 0) {
						const res = await this.$postToken('datas/dibang-daochu', {
							s_time: this.starttime,
							e_time: this.endtime,
						})
						console.log(res)
						if (res.data.code == 200) {
							window.location.href = res.data.result.url
						}
					} else {
						const res = await this.$postToken('datas/maosha-daochu', {
							s_time: this.starttime,
							e_time: this.endtime,
						})
						if (res.data.code == 200) {
							window.location.href = res.data.result.url
						}
					}


				}
			},
			// 返回上一级
			back() {
				this.$router.back();
			}
		}
	};
</script>

<style>
	.box {
		position: relative;
	}

	.flex {
		display: flex;
	}

	/* 标题 */
	.power-top {
		width: 100%;
		margin: 0 36px;
		position: relative;
		display: flex;
	}

	.del {
		display: block;
		left: 0;
	}

	.power-title {
		width: 400px;
		margin: 0 auto;
		text-align: center;
		color: #fff;
		font-size: 32px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.power-title img {
		display: block;
		width: 21px;
		height: 20px;
	}

	/* con */
	.power-con {
		height: 806px;
		padding: 0 30px;
		box-sizing: border-box;
		overflow: hidden;
		margin-top: 50px;
		position: relative;
	}

	.power-con section {
		width: 100%;
		height: 100%;
		background-color: #011633;
	}

	/* biaoti */
	.power-con-title {
		padding: 30px;
		box-sizing: border-box;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.power-con-title-l {
		height: 48px;
		font-size: 20px;
		padding: 0px 0 0px 40px;
		box-sizing: border-box;
		font-family: Alibaba PuHuiTi-Bold, Alibaba PuHuiTi;
		font-weight: bold;
		color: #ffffff;
		background: url(../../assets/images/title\(1\).png) no-repeat;
		display: flex;
		align-items: center;
	}

	.power-con-title-l span {
		display: block;
		padding-bottom: 40px;
	}


	/* biaoge */
	.power-con-list {
		height: 620px;
		padding: 0 30px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.el-table {
		font-size: 14px;
		font-weight: bold;
		color: #d0deee;
		border: none;
		background-color: #011633;
	}

	.el-table__body-wrapper::-webkit-scrollbar {
		width: 6px;
		/*滚动条宽度*/
		height: 60px;
		/*滚动条高度*/
	}

	/*定义滚动条轨道 内阴影+圆角*/
	.el-table__body-wrapper::-webkit-scrollbar-track {
		box-shadow: 0px 1px 3px #011633 inset;
		/*滚动条的背景区域的内阴影*/
		/* border-radius: 10px; */
		/*滚动条的背景区域的圆角*/
		background-color: #011633;
		/*滚动条的背景颜色*/
	}

	/*定义滑块 内阴影+圆角*/
	.el-table__body-wrapper::-webkit-scrollbar-thumb {
		box-shadow: 0px 1px 3px #00a0e9 inset;
		/*滚动条的内阴影*/
		border-radius: 10px;
		/*滚动条的圆角*/
		background-color: #66e1df;
		/*滚动条的背景颜色*/
	}

	th.gutter {
		background-color: #011633;
	}

	td.el-table__cell,
	th.el-table__cell.is-leaf {
		border: none;
	}

	.el-table::before {
		content: '';
		position: absolute;
		background-color: #011633;
		z-index: 1;
	}

	/* 分页 */
	.block {
		margin-top: 20px;
		color: #fff;
		margin: 0 auto;
		display: flex;
		align-items: center;
		position: absolute;
		bottom: 17px;
		left: 520px;
		z-index: 99;
	}

	/* 选中 */
	.el-pagination.is-background .el-pager li:not(.disabled).active {
		background: linear-gradient(180deg, #159aff 0%, #66e1df 100%);
		color: #fff;
		border-radius: 4px 4px 4px 4px;
		border: none;
		box-sizing: border-box;
	}

	/* 未选中 */
	.el-pagination.is-background .el-pager li:not(.disabled) {
		background-color: #011633;
		color: #fff;
		border-radius: 4px 4px 4px 4px;
		border: 1px solid #fff;
		box-sizing: border-box;
	}

	.el-pager li.active+li {
		border: 1px solid #fff;
		/*  */
	}

	/* 上一页  下一页 */
	.el-pagination.is-background .btn-next,
	.block.is-background .btn-prev {
		background-color: #011633;
		color: #fff;
		border-radius: 4px 4px 4px 4px;
		border: 1px solid #fff;
		box-sizing: border-box;
	}

	/* 首页  尾页  */
	.homepage,
	.lastpage {
		width: 54px;
		height: 26px;
		text-align: center;
		line-height: 26px;
		border-radius: 4px 4px 4px 4px;
		border: 1px solid #d0deee;
	}

	/* 跳转 */
	.el-pagination__jump {
		margin-left: 24px;
		color: #fff;
	}

	.el-pagination__editor {
		width: 54px;
		height: 26px;
		background: #d0deee;
		border-radius: 4px 4px 4px 4px;
		box-sizing: border-box;
	}

	.el-pagination button:disabled {
		background-color: #011633;
		border: 1px solid #d0deee;
		box-sizing: border-box;
		border-radius: 4px 4px 4px 4px;
	}

	.el-pagination .btn-next,
	.el-pagination .btn-prev {
		background-color: #011633;
		border: 1px solid #d0deee;
		color: #fff;
		border-radius: 4px 4px 4px 4px;
	}

	.el-pager li {
		margin: 0 4px;
		background-color: #011633;
		border: 1px solid #d0deee;
		border-radius: 4px 4px 4px 4px;
		box-sizing: border-box;
		color: #fff;
	}

	.el-pager li.active {
		background: linear-gradient(180deg, #159aff 0%, #66e1df 100%);
		color: #fff;
		border-radius: 4px 4px 4px 4px;
		border: none;
		cursor: default;
	}

	.el-pagination__editor .el-input__inner {
		background: none;
		border: none;
		outline: none;
		font-size: 12px;
		color: #6c8097;
	}

	/* 日期 */

	.el-input__inner {
		background: #011633;
		border: 1px solid;
		color: #fff;
		border-image: linear-gradient(134deg, rgba(21, 154, 255, 1), rgba(102, 225, 223, 1)) 1 1;
	}

	.el-picker-panel {
		background: #011633;
		color: #fff;
	}

	/*  */
	.el-table__row:hover {
		color: #fff;
	}

	.btn {
		padding: 4px 10px;
		background: none;
		color: #fff;
	}

	.sjdetail {
		justify-content: space-between;
		font-size: 14px;
	}

	.tudetail {
		margin-top: 50px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
	}

	.tudetail div {
		display: flex;
		align-items: center;
		flex-direction: column;
		margin-bottom: 20px;
	}

	.tudetail img {
		width: 200px;
		/* height: 254px; */
		margin-bottom: 10px;
	}

	.el-picker-panel,
	.el-year-table a,
	.el-month-table a {
		background: #011633;
		color: #fff !important;
	}

	.el-range-input {
		background: #011633;
		color: #fff !important;

	}

	.daochu {
		background: #13374c;
		color: #fff;
		padding: 4px 10px;
		border-radius: 4px;
		margin-left: 20px;
		font-size: 16px;
	}

	.int {
		width: 70px;
		border: none;
		outline: none;
		margin: 0 0 0 0;
		background: #102b42;
		color: #fff;
		padding: 4px 0 4px 1em;
	}
</style>